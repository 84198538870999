import { Model } from "./model";

export class ComponentFront implements Model {
  model: {};

  constructor(nodeId: number, name:string) {
    this.build(nodeId,name);
  }

  private build(nodeId: number, name:string) {


    if(name == undefined){
      name = 'frontName';
    }
    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i> <span class="title-box">Front</span>
      
       <div class=" relative">
          <button 
            
            class="properties-menu p-1 focus:outline-none"
          >
            <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </button>
        </div> 

      </div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": '1',
        "input": "output_1"
      }
    ]
  };
  var data = {
    "name": name,
    "type": {"value":"ANGULAR", "options":["ANGULAR"]}, 
    "security": {"value":"MSAL", "options":["MSAL","JWT","NONE"]},
    "clientId": "",
    "tenantId": "",
  };

  var outputs = {
   
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}