<div class="h-[calc(100vh-120px)] flex flex-col bg-gray-50 border rounded-lg shadow-sm bg-white p-2" >
  <!-- Title section -->
  <div class="flex items-start justify-between mb-6">
    <div class="flex items-center space-x-3">
      <div class="p-2 bg-blue-50 rounded-lg">
        <svg class="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4" />
        </svg>
      </div>
      <div>
        <h1 class="text-xl font-semibold text-gray-900">Components</h1>
      </div>
    </div>

     

  </div>

  <!-- Scrollable Content -->
  <div class="flex-1 overflow-y-auto custom-scrollbar">

     <!-- Search Bar -->
     <div class="relative">
      <input
        type="text"
        [(ngModel)]="searchTerm"
        (input)="filterComponents()"
        placeholder="Search components..."
        class="w-full pl-10 pr-4 py-2  text-sm"
      />
      <svg
        class="absolute left-3 top-2.5 h-4 w-4 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </div>
  

    <div class="max-w-2xl mx-auto bg-white h-full">
      <!-- Main content -->
      <div class="p-2">

        <div class="mb-4" *ngFor="let component of filteredComponents">
          <div class="px-4 py-2 text-sm font-medium text-gray-500 sticky top-0 bg-gray-50 z-10">
            {{ component.name }}
            <span class="text-gray-400 text-xs">({{ component.elements.length }})</span>
          </div>
          
          <!-- Components List -->
          <div class="space-y-2">
            <div *ngFor="let item of getLimitedItems(component); let i = index">
              <button
                draggable="true"
                (dragstart)="onDragStart($event, item)"
                class="flex w-full items-center justify-between rounded-md p-3 hover:bg-gray-100 transition-colors duration-200"
              >
                <div class="flex items-center space-x-3">
                  <div class="rounded-md bg-gray-100 p-2">
                    <i class="fa {{ item.icon }}" [class.text-blue-500]="isHighlighted(item)"></i>
                  </div>
                  <div>
                    <div 
                      class="font-medium"
                      [innerHTML]="highlightSearchTerm(item.title)"
                    ></div>
                  </div>
                </div>
                <div class="text-gray-400">
                  <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                  </svg>
                </div>
              </button>
            </div>
  
            <!-- Show More Button -->
            <button
              *ngIf="!searchTerm && component.elements.length > itemsToShow && !component.showAll"
              (click)="toggleShowAll(component)"
              class="w-full text-sm text-blue-500 hover:text-blue-600 py-2 text-center"
            >
              Show {{ component.elements.length - itemsToShow }} more...
            </button>
            
            <!-- Show Less Button -->
            <button
              *ngIf="!searchTerm && component.showAll && component.elements.length > itemsToShow"
              (click)="toggleShowAll(component)"
              class="w-full text-sm text-blue-500 hover:text-blue-600 py-2 text-center"
            >
              Show less
            </button>
          </div>
        </div>
  
        <!-- No Results Message -->
        <div *ngIf="filteredComponents.length === 0" class="text-center py-8 text-gray-500">
          No components found matching "{{ searchTerm }}"
        </div>

  
      </div>
    </div>
  </div>
</div>