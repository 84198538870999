import { Model } from "./model";

export class ServiceBus implements Model {
  model: {};

  constructor(nodeId: number, parentId: number) {
    this.build(nodeId,parentId);
  }

  private build(nodeId: number, parentId: number) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i><span class="title-box">Service Bus</span></div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      <div>Destination</div>
      <input type="text" df-destination>
      <div>Subscriptor</div>
      <input type="text" df-subscriptor>      
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": parentId,
        "input": "output_1"
      }
    ]
  };
  var data = {
    "name": "service bus name",
    "connectionstring": "Endpoint=sb://xx.servicebus.windows.net/xx",
    "destination":"topic",
    "subscriptor": "default",
    "messageType": {"value":"TOPIC", "options":["TOPIC"]}, 
  };

  var outputs = {
   
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}