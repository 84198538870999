<div class="wrapper">

  <div class="accordion accordion-flush" id="accordionComponents">
    <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
      <h2 class="accordion-header mb-0" id="flush-headingOne">
        <button class="accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
          aria-expanded="false" aria-controls="flush-collapseOne">
          Microservice
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionComponents">


        <div class="accordion-body py-4 px-5 grid grid-cols-2 gap-4">
          <div *ngFor="let item of microserviceElements; index as i;" class="flex flex-col items-center drag-drawflow"
            draggable="true" (drag)="onDragOver($event,item) ">
            <div class="relative">
              <i class="fa {{item.icon}}"></i>
            </div>
            <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {{item.title}}
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
      <h2 class="accordion-header mb-0" id="flush-headingTwo">
        <button class="accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
          aria-expanded="false" aria-controls="flush-collapseTwo">
          Azure
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo"
        data-bs-parent="#accordionComponents">

        <div class="accordion-body py-4 px-5 grid grid-cols-2 gap-4">
          <div *ngFor="let item of azureElements; index as i;" class="flex flex-col items-center drag-drawflow"
            draggable="true" (drag)="onDragOver($event,item) ">
            <div class="relative">
              <i [innerHtml]="item.svg"></i>
            </div>
            <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {{item.title}}
            </span>
          </div>
        </div>


      </div>
    </div>
    <div *ngIf="this.tenant != null" class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
      <h2 class="accordion-header mb-0" id="flush-plugin">
        <button class="accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-plugin"
          aria-expanded="false" aria-controls="flush-collapse-plugin">
          Plugins
        </button>
      </h2>
      <div id="flush-collapse-plugin" class="accordion-collapse collapse"
        aria-labelledby="flush-plugin" data-bs-parent="#accordionComponents">

        <div class="accordion-body py-4 px-5 grid grid-cols-2 gap-4">
          <div *ngFor="let item of pluginElementsList; index as i;" class="flex flex-col items-center drag-drawflow"
            draggable="true" (drag)="onDragOver($event,item) ">
            <div class="relative">
              <i class="fa {{item.icon}}"></i>
            </div>
            <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {{item.title}}
            </span>
          </div>
        </div>

      </div>
    </div>
    
    <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
      <h2 class="accordion-header mb-0" id="flush-api-integration">
        <button class="accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-api-integration"
          aria-expanded="false" aria-controls="flush-collapse-api-integration">
          Api Integration
        </button>
      </h2>
      <div id="flush-collapse-api-integration" class="accordion-collapse collapse"
        aria-labelledby="flush-api-integration" data-bs-parent="#accordionComponents">

        <div class="accordion-body py-4 px-5 grid grid-cols-2 gap-4">
          <div *ngFor="let item of apiServiceElementsList; index as i;" class="flex flex-col items-center drag-drawflow"
            draggable="true" (drag)="onDragOver($event,item) ">
            <div class="relative">
              <i class="fa {{item.icon}}"></i>
            </div>
            <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {{item.title}}
            </span>
          </div>
        </div>

      </div>
    </div>


    <div *ngIf="this.tenant != null"
      class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
      <h2 class="accordion-header mb-0" id="flush-heading-components">
        <button class="accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-components"
          aria-expanded="false" aria-controls="flush-components">
          Components
        </button>
      </h2>
      <div id="flush-components" class="accordion-collapse collapse" aria-labelledby="flush-heading-components"
        data-bs-parent="#accordionComponents">

        <div class="accordion-body py-4 px-5 grid grid-cols-2 gap-4">
          <div *ngFor="let item of componentElements; index as i;" class="flex flex-col items-center drag-drawflow"
            draggable="true" (drag)="onDragOver($event,item) ">
            <div class="relative">
              <i class="fa {{item.icon}}"></i>
            </div>
            <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              {{item.title}}
            </span>
          </div>
        </div>

      </div>
    </div>

  </div>


  <div class="col-right">

    <div id="drawflow" style="display:block" #drawflow (dragover)="allowDrop($event)" (drop)="onDrop($event)">



      <div *ngIf="showSpiner" class="flex justify-center items-center">
        <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-3">

        <div (click)="generateService()" class="flex space-x-2 justify-center">
          <button type="button"
            class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            Generate
          </button>
        </div>

        <!--
        <div (click)="clear()" class="flex space-x-2 justify-center">
          <button type="button"
            class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Clear</button>
        </div>
        -->
      </div>

    </div>


  </div>



  <div *ngIf="properties && properties.length > 0"
    class="w-full border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200 text-xs tracking-tight">


    <div class="border-b text-neutral-500">
      <button class="
      relative
      flex
      items-center
      w-full
      py-2
      px-3
      text-base text-sky-900 text-left
      bg-white
      rounded-none
      transition
      focus:outline-none" type="button">
        Properties
      </button>
    </div>


    <div *ngFor="let item of properties; index as i;" class="grid pt-1  grid-cols-2  w-full
        px-2">
      <label class="text-stone-500 hover:border hover:text-stone-800">{{item.key}}</label>
      <input *ngIf="item.values.length == 0" class=" text-stone-500 border text-stone-800" type="text"
        [(ngModel)]="item.value" placeholder="" (input)="somethingChanged(item)">

      <form #f="ngForm">
        <select *ngIf="item.values.length > 0" name="{{item.key}}" [(ngModel)]="item.value"
          (change)="somethingChangedList(item)" class="form-select appearance-none
         block
         w-full
         text-stone-900 border
         bg-white bg-clip-padding bg-no-repeat
         transition
         ease-in-out
         m-0
         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-label="Default select example">
          <option *ngFor="let opt of item.values; index as j;" [ngValue]="opt.value">{{opt.name}}</option>
        </select>
      </form>

    </div>

  </div>

  

</div>

<app-ai-prompt *ngIf="email != null && email == 'oiestradag@gmail.com' " (onSubmit)="onSubmit($event)" (onLoadModel)="onLoadModel($event)"></app-ai-prompt>