import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SocialUser } from 'angularx-social-login';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ContentManagementService } from 'src/app/core/services/content-management-service.service';
import { GAService } from 'src/app/core/services/ga.service';
import { EventMessage } from '../../core/messages/event.message';

@Component({
  selector: 'app-event-message',
  templateUrl: './event-message.component.html',
  styleUrls: ['./event-message.component.scss']

})

export class EventMessageComponent implements OnInit, AfterViewInit {

  errors: any;
  message: any;
  title: any;
  closeResult: string;
  public inputAiSearch: string;


  public user: Observable<SocialUser>;

  constructor(
    
    private eventMessage: EventMessage,

    private readonly authService: AuthService,
    private ga: GAService,
    private contentManagementService: ContentManagementService
  ) { }

 
  ngOnInit() {
    
    this.user = this.authService.getUser();    
    this.user.subscribe(user => {
      if (user != null && user.email != null) {
        this.ga.sendEvent('persona', 'user', user.email);
      }
    });

    this.eventMessage.subscribe('interceptor.error.401', callback => {
      this.errors = callback.data;
      console.log(callback);
      this.logOut();      
    });

    this.eventMessage.subscribe('interceptor.error.502', callback => {
      this.message = 'We are currently giving our website a little TLC to make it even better for you. Please contact us at soporte@microservicios.co. Thank you for your patience, and we can\'t wait to show you the exciting updates we have in store. Stay tuned!';
      this.toggleModal();    
    });

    



    this.eventMessage.subscribe('interceptor.error.429', callback => {
      console.log(callback);
      this.message = 'Has alcanzado la cantidad máxima de solicitudes';
      this.toggleModal();
      
    });


    this.eventMessage.subscribe('info', callback => {
      this.message = callback.data;      
      this.toggleModal();
    });

    this.eventMessage.subscribe('aiModal', callback => {    
      this.toggleModalAI();
    });


   /* this.contentManagementService.site().subscribe(r => {
      if(r != null && r.length > 0){
        if(r[0]){
          //console.log('Actualizar info');          
        } 
       // console.log(r[0]);      
      }      
    });*/
  }


  ngAfterViewInit(){
    this.toggleModal();
    this.toggleModalAI();
  }

  ok() {
    this.toggleModal();   
  }

  aiSearch(){
    this.eventMessage.aiSearch(this.inputAiSearch);
    this.toggleModalAI();
  }

  cancel() {
    this.toggleModal();
  }

  cancelAi() {
    this.toggleModalAI();
  }

  @ViewChild('modalMsg') modalMsg: ElementRef;
  toggleModal() {
       //this.mobMenu.nativeElement.classList
    if (this.modalMsg.nativeElement.className === 'hidden') {
      this.modalMsg.nativeElement.className = ''
    } else {
      this.modalMsg.nativeElement.className = 'hidden'
    }    
  }


  
  @ViewChild('aiModal') aiModal: ElementRef;
  toggleModalAI() {

    if (this.aiModal.nativeElement.className === 'hidden') {
      this.aiModal.nativeElement.className = ''
    } else {
      this.aiModal.nativeElement.className = 'hidden'
    }    
  }




  logOut() {
    this.authService.logout();
  }


  @HostListener('selectDepartamento', ['$event'])
  selectDepartamento(e: CustomEvent) {
    console.log(e.detail);
  }

  @HostListener('selectCiudad', ['$event'])
  selectCiudad(e: CustomEvent) {
    console.log(e.detail);
  }


  @HostListener('mic.error', ['$event'])
  toManyRequest(e: CustomEvent) {
    console.log(e);
    
    if (e.detail.status != null && e.detail.status == 429) {
      this.eventMessage.broadcast({ name: 'interceptor.error.429', data: e.detail });
    } else if (e.detail.status != null && e.detail.status == 429) {
      this.eventMessage.broadcast({ name: 'interceptor.error.401', data: e.detail });
    }
  }



}
