import { Model } from "./model";

export class ServicePath implements Model {
  model: {};

  constructor(nodeId: number, parentId: number, data:any) {
    this.build(nodeId, parentId,data);
  }


  private build(nodeId: number, parentId: number, data:any) {

    let node = "input_1";
    var inputs = {
    };

    var dataModel = {
      "path": (data == undefined || data.path == null) ? "" : data.path,
      "name": (data == undefined || data.name == null) ? "" : data.name,
      "method": {"value": "POST", "options": ["POST", "GET", "PUT", "DELETE"]}
  };


    dataModel.method.value = (data == undefined || data.method   == null) ? "POST" : data.method;

    inputs[node] = {
      "connections": [
        {
          "node": '' + parentId,
          "input": 'output_1'
        }
      ]
    };

    var idEntity = "servicePath_";
    var idEntityAtt = "servicePath_att_";

    var template = `
    <div id="`+ idEntity + nodeId + `" (dragover)="allowDrop($event)" (drop)="onDrop($event)">
    <div  class="box-header"><i class="fas fa-arrow-right "></i> <span class="title-box">Path</span>
    
     <div class=" relative">
          <button 
            
            class="properties-menu p-1 focus:outline-none"
          >
            <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </button>
        </div> 

    </div>
    <div id="`+ idEntityAtt + nodeId + `" class="box box grid gap-2 grid-cols-1">
    <div>Name</div>
    <input type="text" df-name>
    <div>Path</div>
    <input type="text" df-path>
    </div>
  </div>
    `;

 

    this.model = {};
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = dataModel;
  }


  public fill(name:string, path: string, method:string) {
    this.model['data'].path = path;
    this.model['data'].name = name;
    this.model['data'].method.value = method;
  }

  getModel(): any {
    return this.model;
  }
}