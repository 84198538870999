<div class="max-w-sm mx-auto rounded shadow-lg md:max-w-md my-10 p-6 pb-10 bg-gray-100">
    <div class=" flex justify-center border-b border-gray-300">
        <h1 class="font-bold text-lg text-gray-400 pb-1">Login</h1>
    </div>

    <div class="pt-2">
        
        <div class="flex justify-between items-center">
           
           <!-- <button type="button"
                class="w-1/4 mx-1 p-2 font-bold text-white bg-blue-800 rounded focus:outline-none hover:bg-blue-900 disabled:opacity-50"
                style="font-size: 12px;">Facebook</button>
                -->
            <button type="button" (click)="authGoogle()"
                class="w-1/4 mx-1 p-2 font-bold text-white bg-red-600 rounded focus:outline-none hover:bg-red-800"
                style="font-size: 12px;">Google</button>
                <!--
            <button type="button"
                class="w-1/4 mx-1 p-2 font-bold text-white bg-blue-800 rounded focus:outline-none hover:bg-blue-900 disabled:opacity-50"
                style="font-size: 12px;">LinkedIn</button>
            <button type="button"
                class="w-1/4 mx-1 p-2 font-bold text-white bg-blue-500 rounded focus:outline-none hover:bg-blue-900 disabled:opacity-50"
                style="font-size: 12px;">Twitter</button>
            -->
        </div>
    </div>
</div>