

  <table class="text-base max-w-full mx-auto">
    <thead>
      <tr>
        <th class="w-1/5 text-center  font-normal px-4 py-6 sticky top-0">
          <div class="uppercase tracking-wide font-bold text-gray-700">Feature</div>
        </th>
        <th class="w-1/6 text-center font-normal px-4 py-6 sticky top-0 border-gray-100 border bg-gray-100">
          <div class="uppercase tracking-wide font-bold text-gray-700">Java</div>
          <span class="ml-2 text-sm">Spring Boot</span>
        </th>
        <th class="w-1/6 text-center font-normal px-4 py-6 sticky top-0 border-gray-100 border bg-gray-100">
          <div class="uppercase tracking-wide font-bold text-gray-700">Python</div>
          <div class="mt-3">
            <span class="ml-2 text-sm">Fast Api</span>
          </div>
        </th>
        <th class="w-1/6 text-center font-normal px-4 py-6 sticky top-0 border-gray-100 border bg-gray-100">
          <div class="uppercase tracking-wide font-bold text-gray-700">Javascript</div>
          <div class="mt-3">
            <span class="ml-2 text-sm">Hapi</span>
          </div>
        </th>
        <th class="w-1/6 text-center font-normal px-4 py-6 sticky top-0 border-gray-100 border bg-gray-100">
          <div class="uppercase tracking-wide font-bold text-gray-700">Go</div>
          <div class="mt-3">
            <span class="ml-2 text-sm"></span>
          </div>
        </th>
        <th class="w-1/6 text-center  font-normal px-4 py-6 sticky top-0 border-gray-100 border bg-gray-100">
          <div class="uppercase tracking-wide font-bold text-gray-700">Free</div>
        </th>


      </tr>
    </thead>
    <tbody>

      <!--<tr  class="bg-gray-200 border border-gray-200">
        <td colspan="5" class="tracking-wide font-semibold px-4 py-6"> {{item.name}}</td>
      </tr>
    -->
      <tr *ngFor="let item of groups; index as i;">
        <td *ngIf="item.groupName" class=" text-left px-4 py-6 bg-gray-100 font-bold">
          {{item.groupName}}
        </td>
        <td *ngIf="item.feature"  class="text-left px-4 py-6 text-gray-500 font-bold border-gray-100 border">
          {{item.feature}}
        </td>
        <td class="text-center px-4" [ngClass]="{'bg-gray-100':item.groupName, 'border-gray-100 border':item.feature}">
          <svg *ngIf="item.feature  && item.java" class="w-6 h-6 inline-block text-blue-500" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61l-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z" />
          </svg>
        </td>
        <td class=" text-center px-4 py-6" [ngClass]="{'bg-gray-100':item.groupName, 'border-gray-100 border':item.feature}">
          <svg *ngIf="item.feature && item.python" class="w-6 h-6 inline-block text-blue-500" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61l-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z" />
          </svg>
        </td>
        <td class=" text-center px-4 py-6" [ngClass]="{'bg-gray-100':item.groupName, 'border-gray-100 border':item.feature}">
          <svg *ngIf="item.feature && item.javascript" class="w-6 h-6 inline-block text-blue-500" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61l-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z" />
          </svg>
        </td>
        <td class=" text-center px-4 py-6" [ngClass]="{'bg-gray-100':item.groupName, 'border-gray-100 border':item.feature}">
          
        </td>
        <td class=" text-center px-4 py-6" [ngClass]="{'bg-gray-100':item.groupName, 'border-gray-100 border':item.feature}">
          <svg *ngIf="item.feature  && item.free" class="w-6 h-6 inline-block text-blue-500" fill="currentColor" viewBox="0 0 24 24">
            <path
              d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61l-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z" />
          </svg>
        </td>
      </tr>

    </tbody>
  </table>

