<div class="min-h-screen bg-gray-50 flex flex-col">
  <!-- Header -->
  <header class="border-b  border-gray-200 bg-white">
    <div class="flex items-center justify-between px-4 py-2">
      <div class="flex items-center space-x-4">
        <button (click)="toggleSidebar()" class="rounded-lg p-2 focus:outline-none hover:bg-gray-100">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
      <div class="flex items-center space-x-4">
        <button type="button"
          class="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out"
          (click)="generateService()">
          Generate
        </button>
      </div>
    </div>
  </header>

  <!-- Main Content -->
  <div class="flex flex-grow h-[calc(100vh-64px)]">


     <!-- Sidebar Menu -->
      <div *ngIf="showSidbarMenu" 
           class="w-64 max-h-screen border-r border-gray-200 bg-white">
        <app-sidebar-menu [components]="components" (messageEvent)="onDragOverEvent($event)">
        </app-sidebar-menu>
      </div>

    
    <!-- Workflow and Sidebar Menu -->
    <div class="flex flex-grow">
      

     

      <!-- Workflow Canvas -->
        <div id="drawflow" class="flex-grow relative p-4 bg-white" #drawflow 
             (dragover)="allowDrop($event)" (drop)="onDrop($event)">
          <div *ngIf="showSpiner" class="absolute inset-0 flex justify-center items-center">
            <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
    

      <!-- Properties Panel -->
           
      <div *ngIf="showPropertiesMenu"
        class="w-64 max-h-screen border-r border-gray-200 bg-white">
        <app-properties [properties]="properties"></app-properties>
     
   </div>

    </div>
  </div>
</div>

