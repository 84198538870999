import { Model } from "./model";

export class Database implements Model {
  model: {};

  constructor(data:any) {
    this.build(data);
  }


  private build(data:any) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i> <span class="title-box">Database</span>
      
        <div class=" relative">
          <button 
            
            class="properties-menu p-1 focus:outline-none"
          >
            <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </button>
        </div> 

      </div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Url</div>
      <input type="text" df-url>
      <div>Username</div>
      <input type="text" df-username>
      <div>password</div>
      <input type="text" df-password>
    </div>
    `;
  let nodeName = "input_1";
  var inputs = {

  };
  inputs[nodeName] = {
    "connections": [
      {
        "node": '1',
        "input": "output_1"
      }
    ]
  };
  var dataModel = {
    "sqlType": {"value":"POSTGRESQL", "options":["POSTGRESQL","MYSQL","H2"]},
    "url": "url",
    "username": "username",
    "name": "name",
    "password": "password"
  };
 
  if(data.name == undefined){
    data.name = "database-name";
  }
  
  if(data.sqlType == undefined){
    data.sqlType = "MYSQL";
  }
  
  if(data.url == undefined){
    data.url = "url";
  }
  
  if(data.username == undefined){
    data.username = "username";
  }

  if(data.password == undefined){
    data.password = "password";
  }

  dataModel.name = data.name;
  dataModel.sqlType.value = data.sqlType;
  dataModel.url = data.url;
  dataModel.username = data.username;
  dataModel.password = data.password;


  var outputs = {
    "output_1": {
      "connections": [
      ]
    }
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = dataModel;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}