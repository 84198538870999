import { Model } from "./model";

export class AzureDatabase implements Model {
  model: {};

  constructor(nodeId: number, parentId: number) {
    this.build(nodeId,parentId);
  }

  private build(nodeId: number, parentId: number) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i><span class="title-box"> Azure Cosmos DB</span> 

       <div class=" relative">
          <button 
            
            class="properties-menu p-1 focus:outline-none"
          >
            <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </button>
        </div> 
        
      </div>
 
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      <div>Database</div>
      <input type="text" df-database>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": parentId,
        "input": "output_1"
      }
    ]
  };
  var data = {
    "name": "databaseName",
    "key" : "key-key-key-key",
    "database": "databaseName",
    "sqlType": {"value":"COSMOS", "options":["COSMOS"]},     
    "uri": "https://database.documents.azure.com:443/"
  };

  var outputs = {
   
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}