<div class="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Privacy Policy</h1>
    <p class="text-gray-600 mb-4">
      Your privacy is important to us. This policy explains how we handle your data when you use our AI chatbot.
    </p>
  
    <h2 class="text-xl font-semibold text-gray-700 mt-4">1. Information We Collect</h2>
    <p class="text-gray-600">We do not store personal data or chat logs. Some anonymized data may be used for service improvements.</p>
  
    <h2 class="text-xl font-semibold text-gray-700 mt-4">2. Use of Your Data</h2>
    <p class="text-gray-600">We use aggregated data for analytics and service optimization but do not sell or share user data.</p>
  
    <h2 class="text-xl font-semibold text-gray-700 mt-4">3. Third-Party Services</h2>
    <p class="text-gray-600">We may use third-party AI models and APIs, but your conversations are not shared with external companies.</p>
  
    <h2 class="text-xl font-semibold text-gray-700 mt-4">4. Your Rights</h2>
    <p class="text-gray-600">You can request to delete any data related to your usage by contacting us.</p>
  
    <p class="mt-6 text-gray-600">For more details, contact <a href="mailto:soporte@microservicios.co" class="text-blue-500 hover:underline">soporte@microservicios.co</a>.</p>
  </div>
  