import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Directory } from 'src/app/core/model/directory';
import { GeneratorService } from 'src/app/core/services/generator.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  constructor(private generatorService: GeneratorService, private router: Router) { }

  ngOnInit(): void {
    
    this.generatorService.getUser().subscribe(r=>{
      this.directorys = r.directories;
      
    });
  }

  directorys: any;

  selectedDirectory: Directory;

  selectDirectory(directory: Directory): void {
    
    this.directorys.forEach(p => p.status = 'inactive');
    this.selectedDirectory = directory;
    // Update the status of the selected directory to 'active'
    this.selectedDirectory.status = 'active';
       // Reset the status of all directorys to 'inactive'

  }

  chooseDirectory(){

    this.generatorService.saveUser({
      directory: this.selectedDirectory.name
    }).subscribe(r => {
      localStorage.setItem('tenant',this.selectedDirectory.name);  
      this.router.navigate(['/generator']);
    }); 
  }



}
