import { Injectable } from '@angular/core';
declare let gtag:Function; // Declare ga as a function


@Injectable({
  providedIn: 'root'
})
export class GAService  {
  
  constructor()   {  
  }
    
  sendEvent(eventCategory:string, eventAction:string, message: string) {
    //console.log(eventCategory, eventAction, message);
    var response = gtag('send', {
        hitType: 'event',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: message
      });  
      
  }

     
}
