import { Component, Input, OnInit, Output,EventEmitter  } from '@angular/core';

interface MenuItem {
  title: string;
  icon: string;
}

interface MenuSection {
  name: string;
  elements: MenuItem[];
  showAll: boolean;
}

@Component({
  selector: 'app-sidebar-menu',

  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  constructor() { }

  @Input() components: MenuSection[] = [];

  @Output() messageEvent = new EventEmitter<any>();

  searchTerm: string = '';
  itemsToShow: number = 2; // Number of items to show initially

  

  filteredComponents: MenuSection[] = [...this.components];

  getLimitedItems(component: MenuSection): MenuItem[] {
    if (this.searchTerm || component.showAll) {
      return component.elements;
    }
    return component.elements.slice(0, this.itemsToShow);
  }

  toggleShowAll(component: MenuSection): void {
    component.showAll = !component.showAll;
  }

  filterComponents(): void {
    if (!this.searchTerm) {
      this.filteredComponents = [...this.components];
      return;
    }

    const searchTermLower = this.searchTerm.toLowerCase();
    
    this.filteredComponents = this.components
      .map(section => ({
        ...section,
        elements: section.elements.filter(item =>
          item.title.toLowerCase().includes(searchTermLower)
        )
      }))
      .filter(section => section.elements.length > 0);
  }

  highlightSearchTerm(text: string): string {
    if (!this.searchTerm) return text;
    
    const regex = new RegExp(`(${this.searchTerm})`, 'gi');
    return text.replace(regex, '<mark class="bg-yellow-200">$1</mark>');
  }

  isHighlighted(item: MenuItem): boolean {
    return this.searchTerm ? 
      item.title.toLowerCase().includes(this.searchTerm.toLowerCase()) : 
      false;
  }

  onDragStart(event, nodeType) { 
    this.messageEvent.emit({data:nodeType.data , key:nodeType.key});
  }

  ngOnInit(): void {
    this.filteredComponents = [...this.components];
  }

}
