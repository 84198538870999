import { Model } from "./model";

export class Storage implements Model {
  model: {};

  constructor(nodeId: number, parentId: number) {
    this.build(nodeId,parentId);
  }

  private build(nodeId: number, parentId: number) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i> <span class="title-box">Azure Storage Account</span></div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Account Name</div>
      <input type="text" df-accountName>
      <div>Connection String</div>
      <input type="text" df-connectionstring>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": parentId,
        "input": "output_1"
      }
    ]
  };
  var data = {
    "accountName": "accountName",
    "connectionstring": "DefaultEndpointsProtocol=https;AccountName=yourname;AccountKey=Key=core.windows.net"
  };

  var outputs = {
    "output_1": {
      "connections": [
      ]
    }
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}