import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export class ModelElement {
    title: string;
    key: string;
    group: string;
    icon: string;
    svg:SafeHtml;
    data:any;
 
    constructor(private sanitizer: DomSanitizer,title,key,group,icon,svg,data) {
      this.title = title;
      this.key = key;
      this.group = group;
      this.icon = icon;
      this.svg = this.sanitizer.bypassSecurityTrustHtml(svg);
      this.data = data;
    }

  }
