export const ModelConstants = {
  entity: 'entity',
  apiservice: 'apiservice',
  apiServicePath: 'apiServicePath',
  azureresources: 'azureresources',
  servicebus:'servicebus',
  entityAttribute:'entityAttribute',
  insight: 'insight',
  storage: 'storage',
  storageTable: 'tables',
  azuredatabase: 'azuredatabase',
  front: 'front',
  database: 'database',
  plugin: 'plugins'


};
