import { Model } from "./model";

export class Plugin implements Model {

  model: {};

  constructor(name: string, category: string, json: string) {
    this.build(name,category,json);
  }

  private build(name: string, category: string, json: string) {

    var template = `
    <div>
      <div class="title-box"><i class="fas fa-cubes "></i>Plugin</div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      <div>Category</div>
      <input type="text" df-category>
      <div>Data</div>
      <textarea df-json>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": '1',
        "input": "output_1"
      }
    ]
  };
  var data = {
      "name": name,
      "category": category,
      "json": json,
    };

  var outputs = {
    "output_1": {
      "connections": [
      ]
    }
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}