import { Component, Input, OnInit } from '@angular/core';
import { EventMessage } from 'src/app/core/messages/event.message';
import { GeneratorService } from 'src/app/core/services/generator.service';

interface InputField {
  label: string;
  type: 'text' | 'select';
  value: string;
  options?: { label: string; value: string }[];
  icon?: string;
}

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {

  selectedWorkspace: string = 'workspaces';
  selectedAttribute: string = 'billedSeatCount';

  constructor( private eventMessage: EventMessage) {}

  
  updateField(field: string, value: string) {
    console.log(`Updating ${field} to ${value}`);
  }

  somethingChangedList(value: any) {
   this.eventMessage.onListChange(value);
  }

  
  somethingChanged(value: any) {
   this.eventMessage.onValueChange(value);
  }



  @Input() properties: [] ;


  

  accountActions = [
    {
      name: 'Create an account',
      isOpen: false,
      subItems: ['New account', 'Import account', 'Quick create']
    },
    {
      name: 'Delete an account',
      isOpen: false,
      subItems: ['Permanent delete', 'Soft delete', 'Batch delete']
    },
    {
      name: 'Get an account',
      isOpen: false,
      subItems: ['View details', 'Export data', 'Download report']
    },
    {
      name: 'Get many accounts',
      isOpen: false,
      subItems: ['List view', 'Grid view', 'Export all']
    },
    {
      name: 'Update an account',
      isOpen: false,
      subItems: ['Edit details', 'Bulk update', 'Sync data']
    }
  ];

  contactActions = [
    {
      name: 'Create an account contact',
      isOpen: false,
      subItems: ['Add contact', 'Import contacts', 'Bulk create']
    },
    {
      name: 'Delete an account contact',
      isOpen: false,
      subItems: ['Remove contact', 'Batch remove', 'Archive']
    },
    {
      name: 'Update an account contact',
      isOpen: false,
      subItems: ['Edit contact', 'Mass update', 'Sync contacts']
    }
  ];



  
  ngOnInit(): void {
  }

}
