import { Model } from "./model";

export class AzureResourceGroup implements Model {
  model: {};

  constructor(nodeId: number) {
    this.build(nodeId);
  }

  private build(nodeId: number) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i><span class="title-box"> Azure Resource Group</span></div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": '1',
        "input": "output_1"
      }
    ]
  };
  var data = {
    "name": "rg-test"
  };

  var outputs = {
    "output_1": {
      "connections": [
      ]
    }
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = data;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}