<section class="container p-6 mx-auto bg-white dark:bg-gray-800">

  <div class="flex items-center justify-center">
    <div class="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

      <div *ngFor="let item of models;let indice=index" class="w-full max-w-xs text-center">
        <img class="object-cover object-center w-full h-48 mx-auto rounded-lg"
          src="{{item.attributes.images[0]}}" alt="avatar" />

        <div class="mt-2">
          <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200">{{item.attributes.description}}</h3>
          <span class="mt-1 font-medium text-gray-600 dark:text-gray-300">{{item.attributes.name}}</span>
        </div>
        <div class="rounded-md shadow py-3 px-9">
          <a href="/generator/{{item.id}}"
            class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
            Generar
          </a>
        </div>
      </div>

    </div>
  </div>
</section>