import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RequestInterceptor } from './core/interceptors/request-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // ✅ Import this

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import {
  GoogleLoginProvider
} from 'angularx-social-login';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BuildingsBlocksComponent } from './buildings-blocks/buildings-blocks.component';
import { GeneratorComponent } from './generator/generator.component';
import { LoginComponent } from './login/login.component';
import { EventMessageComponent } from './shared/messages/event-message.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { DirectoryComponent } from './directory/directory.component';
import { AiPromptComponent } from './ai-prompt/ai-prompt.component';
import { HomeComponent } from './home/home.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { MarkdownModule } from 'ngx-markdown';
import { ChatComponent } from './components/chat/chat.component';
import { SecurityContext } from '@angular/core';



import localeEsCO from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCO, 'es-CO');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BuildingsBlocksComponent,
    GeneratorComponent,
    EventMessageComponent,
    LoginComponent,
    HomeComponent,
    StoryComponent,
    ContactComponent,
    FeaturesComponent,
    DirectoryComponent,
    AiPromptComponent,
    SidebarMenuComponent,
    PropertiesComponent,
    ChatComponent
  ],
  imports: [
    SocialLoginModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, 
    HttpClientModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
   
    }),
    CommonModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    EditorModule,
  
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CO' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '430643588491-kl0qk8n4cp4cmhvqkb3dfnig76naij0m.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }

  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
