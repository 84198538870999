import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentManagementService } from '../core/services/content-management-service.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent  implements OnInit,AfterViewInit {

  constructor(private contentManagementService: ContentManagementService,
    private route: ActivatedRoute) { }

  public article: any;
  public published_at: any;
  @ViewChild('container') d1:ElementRef;
  

  ngAfterViewInit(){
    var name = this.route.snapshot.paramMap.get('name');
     
    
    /*this.contentManagementService.article(name).subscribe(r => {
      this.article = r.data[0].attributes;  
      
      this.published_at = new Date(this.article.published_at).toDateString();
        
      let newTitleElem = document.createElement('div');
      newTitleElem.innerHTML = this.article.content;      
      this.d1.nativeElement.appendChild(newTitleElem);;
    });*/
  }

  ngOnInit(): void {
     
    
  }

}
