import { Model } from "./model";

export class Insight implements Model {
  model: {};

  constructor(nodeId: number, parentId: number, data:any) {
    this.build(nodeId,parentId,data);
  }

  private build(nodeId: number, parentId: number, data:any) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i> <span class="title-box">Insight</span></div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Name</div>
      <input type="text" df-name>
      <div>Key</div>
      <input type="text" df-key>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": parentId,
        "input": "output_1"
      }
    ]
  };

  if(data.name == undefined){
    data.name = 'Insights';
  }

  if(data.key == undefined){
    data.key = 'key-key-key-key';
  }

  var dataModel = {
    "name": data.name,
    "key" : data.key
  };

  var outputs = {
    
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = dataModel;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}