import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentManagementService } from '../core/services/content-management-service.service';
import { GAService } from '../core/services/ga.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public article: any;
  public published_at: any;
  @ViewChild('container') d1:ElementRef;

  constructor(private contentManagementService: ContentManagementService,
    private gAService : GAService,
    private route: ActivatedRoute) { }


    ngAfterViewInit(){
           
      /*this.contentManagementService.article('mision').subscribe(r => {
        this.article = r.data[0].attributes;  
        this.published_at = new Date(this.article.published_at).toDateString();
                
        let newTitleElem = document.createElement('div');
        newTitleElem.innerHTML = this.article.content;      
        this.d1.nativeElement.appendChild(newTitleElem);;
      });*/
    }

   public promptText: string = '';
   public isDark: boolean = false;


   userInput: string = '';
   chatResponse: string = '';
 
   @ViewChild('responseContainer') responseContainer!: ElementRef;
 
   sendMessage() {
     // Simulate a chat response
     this.chatResponse += `\n\n**AI:** ${this.userInput}`;
     this.userInput = '';
 
     // Scroll to the bottom
     setTimeout(() => {
       this.scrollToBottom();
     }, 100);
   }
 
   scrollToBottom() {
     if (this.responseContainer) {
       this.responseContainer.nativeElement.scrollTop = this.responseContainer.nativeElement.scrollHeight;
     }
   }

   
   actions = [
    { text: 'Import Chat', icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" /></svg>' },
    { text: 'Import Folder', icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7a2 2 0 012-2h4l2 2h6a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V7z" /></svg>' },
    { text: 'Clone A Git Repo', icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7V3m0 0l-4 4m4-4l4 4M8 21v-4m0 0l-4 4m4-4l4 4M20 12h-4m-4 0H4" /></svg>' }
  ];
  
  toggleTheme() {
    this.isDark = !this.isDark;
  }
    
  ngOnInit(): void {
    this.gAService.sendEvent('a','b','c');
  }

}
