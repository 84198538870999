import { Model } from "./model";

export class Entity implements Model {
  model: {};

  constructor(nodeId: number, name:string) {
    this.build(nodeId,name);
  }

  private build(nodeId: number, name:string) {


    if(name == undefined){
      name = "EntityName";
    }
    var idEntity = "entity_";
    var idEntityTitle = "entity_title_";
    var idEntityAtt = "entity_att_";
    var id = "node_" + nodeId;
   

    var data = { name: name };


    var template = `
      <div id="`+ idEntity + nodeId + `" (dragover)="allowDrop($event)" (drop)="onDrop($event)">
        <div  class="box-header"><i class="fas fa-code "></i> <span class="title-box" id="`+ idEntityTitle + nodeId + `"> `+ data.name + `</span></div>
        <div id="`+ idEntityAtt + nodeId + `" class="box">
        </div>
      </div>
      `;
    let node = "input_" + 1;
    var inputs = {

    };
    inputs[node] = {
      "connections": [
        {
          "node": "1",
          "input": "output_1"
        }
      ]
    };


    
    this.model  = { };
    this.model['data'] = data;
    this.model['template'] = template;
    this.model['inputs'] = inputs;

  }

  getModel():any {
    return this.model;
  }
}