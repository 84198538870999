<div class="h-[calc(100vh-120px)] flex flex-col bg-gray-50 border rounded-lg shadow-sm bg-white p-2" *ngIf="properties && properties.length > 0">
  <!-- Title section -->
  <div class="flex items-start justify-between mb-6">
    <div class="flex items-center space-x-3">
      <div class="p-2 bg-blue-50 rounded-lg">
        <svg class="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4" />
        </svg>
      </div>
      <div>
        <h1 class="text-xl font-semibold text-gray-900">Attributes</h1>
      </div>
    </div>

  </div>

  <!-- Scrollable Content -->
  <div class="flex-1 overflow-y-auto custom-scrollbar">
    <div class="max-w-2xl mx-auto bg-white h-full">
      <!-- Main content -->
      <div class="p-2">



        <div class="">
         
        
          <div class="mt-2 space-y-1 text-xs text-gray-700">
           
            <ng-container *ngFor="let item of properties; index as i;">


              <div class="flex justify-between text-right">
                <span class="font-bold text-gray-500 w-14">  {{item.key}}:</span>
                <select *ngIf="item.values.length > 0" style="text-align: right;" name="{{item.key}}" [(ngModel)]="item.value"
                (change)="somethingChangedList(item)" class="w-14 bg-transparent border-none focus:ring-0 text-gray-900">
                <option *ngFor="let opt of item.values; index as j;" [ngValue]="opt.value">{{opt.name}}</option>
                </select>

                <input *ngIf="item.values.length == 0" style="text-align: right;" class=" text-gray-900" type="text"
                [(ngModel)]="item.value" placeholder="" (input)="somethingChanged(item)">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>