import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from '../core/services/content-management-service.service';

@Component({
  selector: 'app-buildings-blocks',
  templateUrl: './buildings-blocks.component.html',
  styleUrls: ['./buildings-blocks.component.scss']
})
export class BuildingsBlocksComponent implements OnInit {

  constructor(
    private contentManagementService: ContentManagementService
  ) { }
  public models: any;
  
  ngOnInit() {
    /*this.contentManagementService.generators().subscribe(r => {
      this.models = r.data;
      console.log(r);      
    });*/

  }

}
