import { Component, OnInit,Output,EventEmitter  } from '@angular/core';
import { GeneratorService } from '../core/services/generator.service';


@Component({
  selector: 'app-ai-prompt',
  templateUrl: './ai-prompt.component.html',
  styleUrls: ['./ai-prompt.component.scss']
})
export class AiPromptComponent implements OnInit {

  constructor(
    private readonly generatorService: GeneratorService
  ) { }

  @Output() onLoadModel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  onJsonFileSelected(event: any){
 
    if(event.target!= null && event.target.files != null && event.target.files.length == 1){
      this.getModelFromFile(event.target.files[0]);
    }
  }

  aiSearch(data){
      this.onSubmit.emit(data);
  }

  private async getModelFromFile(file: any) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = reader.result?.toString() || '';
      this.onLoadModel.emit(content);
    };

    reader.readAsText(file);
}

}
