import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { BuildingsBlocksComponent } from './buildings-blocks/buildings-blocks.component';
import { GeneratorComponent } from './generator/generator.component';
import { LoginComponent } from './login/login.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { DirectoryComponent } from './directory/directory.component';
import { TermsComponent } from './policies/terms/terms.component';
import { PrivacyComponent } from './policies/privacy/privacy.component';

const routes: Routes =[
    { path: '',             component: HomeComponent },
    { path: 'blocks',             component: BuildingsBlocksComponent },
    { path: 'generator/:uuid',          component: GeneratorComponent },
    { path: 'generator',          component: GeneratorComponent },
    { path: 'story/:name',          component: StoryComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'contact',          component: ContactComponent },
    { path: 'features',          component: FeaturesComponent },
    { path: 'terms',          component: TermsComponent },
    { path: 'privacy',          component: PrivacyComponent },
    { path: 'directory',          component: DirectoryComponent },
    
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
