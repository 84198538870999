<div class="max-w-6xl mx-auto px-4 md:py-4 lg:py-20 text-center">
  <h1 class="text-6xl font-bold  md:mb-6 lg:mb-16 tracking-wide" [ngClass]="isDark ? 'text-white' : 'text-gray-900'">
    Bring your ideas to life!
  </h1>


  <app-chat></app-chat>

  
  
 

</div>