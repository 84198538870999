import { Model } from "./model";

export class Service implements Model {
  model: {};

  constructor(nodeId: number, data:any) {
    this.build(nodeId,data);
  }


  private build(nodeId: number, data:any) {

    var template = `
    <div>
      <div class="box-header"><i class="fas fa-cubes "></i> <span class="title-box">Api Service</span>
      
       <div class=" relative">
          <button 
            
            class="properties-menu p-1 focus:outline-none"
          >
            <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </button>
        </div> 

      </div>
      <div class="box box grid gap-2 grid-cols-1">
      <div>Url</div>
      <input type="text" df-url>
      <div>Client Secret</div>
      <input type="text" df-clientsecret>
      <div>Client Id</div>
      <input type="text" df-clientid>
      <div>Token Path</div>
      <input type="text" df-tokenpath>
      </div>
    </div>
    `;
  let node = "input_1";
  var inputs = {

  };
  inputs[node] = {
    "connections": [
      {
        "node": '1',
        "input": "output_1"
      }
    ]
  };


  
  var dataModel = {
    "authType": {"value":"OAUTH2", "options":["OAUTH2","FIXED_TOKEN","NONE","BASIC"]},
    "clientid": (data == undefined || data.clientId == null) ? "" : data.clientId,
    "clientsecret": (data == undefined || data.clientSecret == null) ? "" : data.clientSecret ,
    "name": (data == undefined || data.name == null) ? "" : data.name ,
    "tokenpath": (data == undefined || data.tokenPath == null) ? "" : data.tokenPath ,
    "token": (data == undefined || data.token == null) ? "" : data.token  ,
    "url": (data == undefined || data.url == null) ? "" : data.url 
  };
  dataModel.authType.value = data.authType;

  var outputs = {
    "output_1": {
      "connections": [
      ]
    }
  };

    this.model  = { };
    this.model['template'] = template;
    this.model['inputs'] = inputs;
    this.model['data'] = dataModel;
    this.model['outputs'] = outputs;


  }

  getModel():any {
    return this.model;
  }
}