import { Component, OnInit } from '@angular/core';
import { EventMessage } from '../core/messages/event.message';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private eventMessage : EventMessage, ) { }

  public name : string ='';
  public mail : string ='';
  public message : string ='';


  ngOnInit(): void {
  }

  sendMessage(){
    
    this.eventMessage.broadcast({name : 'info', data: 'Gracias por tus comentarios '+this.name });                        
   
  }

}
