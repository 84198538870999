import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentManagementService } from '../../core/services/content-management-service.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public article: any;
  public published_at: any;
  @ViewChild('container') d1:ElementRef;

  constructor(private contentManagementService: ContentManagementService,
    private route: ActivatedRoute) { }


    ngAfterViewInit(){
           
      /*this.contentManagementService.article('mision').subscribe(r => {
        this.article = r.data[0].attributes;  
        this.published_at = new Date(this.article.published_at).toDateString();
                
        let newTitleElem = document.createElement('div');
        newTitleElem.innerHTML = this.article.content;      
        this.d1.nativeElement.appendChild(newTitleElem);;
      });*/
    }

   public promptText: string = '';

   
  importChat(): void {
    console.log('Import chat clicked');
  }

  importFolder(): void {
    console.log('Import folder clicked');
  }

  cloneRepo(): void {
    console.log('Clone repo clicked');
  }

    
  ngOnInit(): void {
  }

}
