import { AzureResourceGroup } from "../core/model/azure-resoruce-group";
import { AzureDatabase } from "../core/model/azuredatabase";
import { ComponentFront } from "../core/model/component-front";
import { Database } from "../core/model/database";
import { Entity } from "../core/model/entity";
import { Insight } from "../core/model/insight";
import { ModelConstants } from "../core/model/model-constants";
import { Plugin } from "../core/model/plugin";
import { Property } from "../core/model/property";
import { Service } from "../core/model/service";
import { ServiceBus } from "../core/model/service-bus";
import { ServicePath } from "../core/model/service-path";
import { Storage } from "../core/model/storage";
import { StorageTable } from "../core/model/storage-table";

export class Modeler {

  public apiServices: number = 0;
  public entities: number = 0;
  public plugins: number = 0;
  public entityAttributes: number = 0;
  public servicePaths: number = 0;
  public azureResources: number = 0;
  public servicebuses: number = 0;
  public insights: number = 0;
  public azuredatabase: number = 0;
  public database: number = 0;
  public storages: number = 0;
  public storageTables: number = 0;
  public componentFronts: number = 0;


  public nodes: number = 1;
  
  public properties: Property[] = [];

  public nodeSelectedIndex: number = 0;
  public nodeSelectedName: number = 0;

  public editor: any;

  public initData =

    {
      "drawflow": {
        "Home": {
          "data": {
            "1": {
              "id": 1,
              "name": "microservice",
              "data": {
                "architecturalStyle": { "value": "FLAT", "options": ["FLAT", "LAYOUTONE","MICROSERVICE"] },
                "targetCloud": { "value": "AZURE", "options": ["AZURE", "HEROKU","DOCKER_SWARM"] },
                "ciCdPlatformType": { "value": "AZURE_DEVOPS", "options": ["AZURE_DEVOPS", "JENKINS"] },
                "targetLanguage": { "value": "JAVA", "options": ["JAVA", "PYTHON", "JAVASCRIPT", "GO"] },
                "javaVersion": { "value": "11", "options": ["11","17"] },
                "infrastructure": { "value": "TERRAFORM", "options": ["TERRAFORM"] },
                "security":  { "value": "NONE", "options": ["NONE", "MSAL", "SELF_BASIC", "SELF_JWT", "API_GATEWAY"] },
                "businessLine": "test",
                "contactMail": "test@test.com",
                "contactName": "oscar",
                "contactUrl": "http://test.com",
                "description": "test",
                "developerEmail": "test@test.com",
                "developerName": "oscar",
                "name": "account-service",
                "package_": "co.microservicios",
                "prefix": "app",
                "sufix": "api",
                "wikiUrl": "http://test.com",
                "plugins": [
                    {
                        "name": "KafkaPlugin",
                        "category": "integration",
                        "json": "{\"servers\":\"upstash.io:9092\",\"jaas\":{\"config\":\"org.apache.kafka.common.security.scram.ScramLoginModule\"},\"topic\":\"topicName\"}"
                    },
                    {
                        "name": "ApigeePlugin",
                        "category": "integration",
                        "json": "{\"servers\":\"upstash.io:9092\",\"jaas\":{\"config\":\"org.apache.kafka.common.security.scram.ScramLoginModule\"},\"topic\":\"topicName\"}"
                    }
                ]
              },
              "class": "microservice",
              "html": "<div> <div class=\"box-header\"><i class=\"fas fa-cube \"></i><span class=\"title-box\"> Microservice</span>      <div class=\" relative\"> <button  class=\"properties-menu p-1 focus:outline-none\" > <svg class=\"w-5 h-5 text-gray-600\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" viewBox=\"0 0 24 24\">   <circle cx=\"12\" cy=\"12\" r=\"1\"></circle>   <circle cx=\"19\" cy=\"12\" r=\"1\"></circle>   <circle cx=\"5\" cy=\"12\" r=\"1\"></circle> </svg> </button> </div>    </div>            <div class=\"box grid gap-2 grid-cols-1\"> <div>Name</div>  <input type=\"text\" df-name> <div>Description</div> <input type=\"text\" df-description> <div>Package</div> <input type=\"text\" df-package_> <div>Prefix</div> <input type=\"text\" df-prefix> <div>Sufix</div> <input type=\"text\" df-sufix> </div> </div>",
              "typenode": false,
              "inputs": {},
              "outputs": {
               
                "rigth": {
                  "connections": [

                  ]
                }
              },
              "pos_x": 41,
              "pos_y": 91
            }
          }
        }
      }
    }


    

  constructor() {
  }


  public numNodes: number = 1;
  public radius: number = 1;
  public angleIncrement: number = 1;

  
  public initEditor(editor) {
    this.editor = editor;
    editor.reroute = true;
    editor.editor_mode = 'edit';
    editor.start();
   
    editor.import(this.initData);

     this.numNodes = 20; // Excluding the root node
     this.radius = 30; // Adjust as needed
     this.angleIncrement = (2 * Math.PI) / this.numNodes;

   
  }



  onlyName(name) {
    return name == ModelConstants.entity;
  }



  newElement(nodeType, data) {

    
  // Adjusting the angle calculation
  const maxAngle = 50; // Maximum angle allowed
  const minAngle = 10;   // Minimum angle allowed

  // Calculate the angle within the specified range
  const angleIncrement =+ 5;  // Angle increment between nodes
  let angle = (minAngle + angleIncrement * this.nodes) * (Math.PI / 180); // Convert degrees to radians

  // If angle exceeds the maximum angle, set it to the maximum angle
  if (angle > maxAngle * (Math.PI / 180)) {
      angle = maxAngle * (Math.PI / 180);
  }

  // Calculate x and y positions using the updated angle calculation
  var x = 60 * this.nodes  + (this.radius * Math.cos(angle));
  var y = 1  * this.nodes  + (this.radius * Math.sin(angle));
   
  if(x < 50){
    x = 250;
  }

    switch (nodeType) {
      case ModelConstants.entity:
        return this.addEntity(nodeType, x, y, data);
      case ModelConstants.plugin:
          this.addPlugin(nodeType, x, y, data.nodePlugin.name, data.nodePlugin.category, data.nodePlugin.json);
          break;  
      case ModelConstants.apiservice:      
         return this.addService(nodeType, x, y,data);     
      case ModelConstants.entityAttribute:
        if (this.editor.getNodesFromName(nodeType).length == 0 && data.parent != undefined) {
          this.addEntityAttribute(data);
        }
        break;
      case ModelConstants.apiServicePath:
        this.addServicePath(nodeType, x + 10, y + 10,data);
        break;
      case ModelConstants.database:
        if (this.editor.getNodesFromName(nodeType).length == 0 ) {
          this.addDatabase(nodeType, x, y,data)
        }
        break;
      case ModelConstants.azureresources:
        if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
          this.addAzureResource(nodeType, x, y);
        }
        break;
      case ModelConstants.servicebus:
        if (this.editor.getNodesFromName(nodeType).length == 0 ) {
          this.addServiceBus(nodeType, x, y,data);
        }
        break;
      case ModelConstants.insight:
        if (this.editor.getNodesFromName(nodeType).length == 0 ) {
             this.addInsight(nodeType, x, y,data);
        }
        break;
      case ModelConstants.azuredatabase:
        this.addAzuredatabase(nodeType, x, y);
        break;
      case ModelConstants.storage:
        this.addStorage(nodeType, x, y);
        break;
      case ModelConstants.storageTable:
        this.addStorageTable(nodeType, x, y);
        break;
      case ModelConstants.front:
        if (this.editor.getNodesFromName(ModelConstants.front).length == 0 ) {
          this.addComponentFront(nodeType, x, y, data);
        }
        break;  
    }
  }




  addServicePath(nodeName, x, y,data:any) {
    var parent;

    console.log(data);
    
    if (this.editor.getNodesFromName(ModelConstants.apiservice).length == 0 ) {
      
      parent = this.addService(ModelConstants.apiservice, x, y,{});
    }else if(data.parent != undefined){
      parent = data.parent;
    }   

    this.servicePaths++;


    var e: ServicePath = new ServicePath(this.nodes, parent,data.path);
      
    var index = this.editor.addNode(nodeName+parent, 1, 0, this.editor.getNodeFromId(parent).pos_x , this.editor.getNodeFromId(parent).pos_y , nodeName + this.servicePaths, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }


  
  addDatabase(componentName, x, y,data) {
       
    this.database++;
    var e: Database = new Database(data);
  
    var index = this.editor.addNode(componentName, 1, 0, x , y , componentName , e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  addEntity(componentName, x, y, data): number {
   
    var index = this.editor.addNode(componentName, 1, 0, 300  , 320 + (Math.floor(Math.random() * 5)) ,componentName,{},'');
  
    var e: Entity = new Entity(index, data.name);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].data = e.getModel().data;
    this.editor.drawflow.drawflow.Home.data[index].html = e.getModel().template;
    
    this.editor.import(this.editor.drawflow);
    this.entities++;
    this.nodes++;
    return index;
  }

  addPlugin(componentName, x, y, name: string, category: string, json: string): number {

    var index = this.editor.addNode(componentName, 1, 0, x , y ,componentName,{},'');
        
    var e: Plugin = new Plugin(name,category,json);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].data = e.getModel().data;
    this.editor.drawflow.drawflow.Home.data[index].html = e.getModel().template;
    
    this.editor.import(this.editor.drawflow);
    this.entities++;
    this.nodes++;

    
    return index;
  }


  

  addEntityAttribute(data:any) {

    var idEntity = "entity_";
    var idEntityAtt = "entity_att_";

    var nameAtt = "name" + (this.entityAttributes + 1);
    var typeAtt = "type" + (this.entityAttributes + 1);

    var newHtml = '<input type="text" df-' + nameAtt + '> ';
    var typeHtml = '<input type="text" df-' + typeAtt + '> ';
    var d1 = data.elementRef.nativeElement.querySelector('#' + idEntityAtt + data.parent);
    
    if (d1 != null) {
      d1.insertAdjacentHTML('beforeend', newHtml);
      //d1.insertAdjacentHTML('beforeend', typeHtml); 
      d1 = data.elementRef.nativeElement.querySelector('#' + idEntity + data.parent,this.editor.drawflow.drawflow.Home.data);
      if (d1 != null) {
        this.editor.drawflow.drawflow.Home.data[data.parent].html = d1.outerHTML;
        if(data.name == undefined){
          data.name = 'someName';
        }
        this.editor.drawflow.drawflow.Home.data[data.parent].data[nameAtt] = data.name;
        this.editor.drawflow.drawflow.Home.data[data.parent].data[typeAtt] = { "value": data.type, "options": ["String", "boolean", "Date", "int"] };
      }
      this.editor.import(this.editor.drawflow);
      this.entityAttributes++;
    }


  }

  addService(name, x, y,data) {

    var e: Service = new Service(this.nodes,data);
    this.apiServices++;
    var index = this.editor.addNode(name, 1, 1, x, y, name + this.apiServices, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
    return index;
  }

 


  addAzureResource(name, x, y) {
    
    var e: AzureResourceGroup = new AzureResourceGroup(this.nodes);
    this.azureResources++;
    var index = this.editor.addNode(name, 1, 1, x, y, name + this.azureResources, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  addServiceBus(name, x, y,data) {      

    if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
      this.addAzureResource(ModelConstants.azureresources, x, y);
    }
   
    var parent = this.editor.getNodesFromName(ModelConstants.azureresources);
    var e: ServiceBus = new ServiceBus(this.nodes, parent);
    this.servicebuses++;
    var index = this.editor.addNode(name, 1, 0, x, y, name + this.servicebuses, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }


  addInsight(name, x, y,data) {
  
    if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
      this.addAzureResource(ModelConstants.azureresources, x, y);
    }      

    var parent = this.editor.getNodesFromName(ModelConstants.azureresources);
    var e: Insight = new Insight(this.nodes, parent,data);
    this.insights++;
    var index = this.editor.addNode(name, 1, 0, x, y, name + this.insights, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  

  addAzuredatabase(name, x, y) {
    if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
      this.addAzureResource(ModelConstants.azureresources, x, y);
    }

    var parent = this.editor.getNodesFromName(ModelConstants.azureresources);
    var e: AzureDatabase = new AzureDatabase(this.nodes, parent);
    this.azuredatabase++;
    var index = this.editor.addNode(name, 1, 0, x, y, name + this.azuredatabase, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  addStorage(name, x, y) {
    if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
      this.addAzureResource(ModelConstants.azureresources, x, y);
    }

    var parent = this.editor.getNodesFromName(ModelConstants.azureresources);
    var e: Storage = new Storage(this.nodes, parent);
    this.storages++;
    var index = this.editor.addNode(name, 1, 1, x, y, name + this.storages, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  addStorageTable(name, x, y) {
    if (this.editor.getNodesFromName(ModelConstants.azureresources).length == 0) {
      this.addAzureResource(ModelConstants.azureresources, x, y);
    }
    if (this.storages == 0) {
      this.addStorage(ModelConstants.storage, x, y);
    }
     

    var parent = this.editor.getNodesFromName(ModelConstants.storage);
    var e: StorageTable = new StorageTable(this.nodes, parent);
    this.storageTables++;
    var index = this.editor.addNode(name, 1, 0, x, y, name + this.storageTables, e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }

  addComponentFront(componentName, x, y, data) {

    var e: ComponentFront = new ComponentFront(this.nodes, data.name);
    this.componentFronts++;
    var index = this.editor.addNode(componentName, 1, 0, x, y, componentName , e.getModel().data, e.getModel().template);
    this.editor.drawflow.drawflow.Home.data[index].inputs = e.getModel().inputs;
    this.editor.drawflow.drawflow.Home.data[index].outputs = e.getModel().outputs;
    this.editor.import(this.editor.drawflow);
    this.nodes++;
  }







  export() {
    return this.editor.export();
  }




  validateOption(jsonObject) {
    // Check if the object is valid JSON and has required properties
    if (typeof jsonObject !== 'object' || !jsonObject.hasOwnProperty('value') || !jsonObject.hasOwnProperty('options')) {
      return false; // Not valid JSON or missing properties
    }
  
    // Extract the value and options
    const value = jsonObject.value;
    const options = jsonObject.options;
  
    // Convert options to uppercase for case-insensitive comparison
    const upperCaseOptions = options.map(option => option.toUpperCase());
  
    // Check if value is present in the uppercase options array
    return upperCaseOptions.includes(value.toUpperCase());
  }

getSelectedValue(select: any){
  if(this.validateOption(select)){
    return select.value;
  }else{
    return select.options[0];
  }
}


  /**
   * 
   * @returns json microservice
   */
  getModel() {
    
    var mic = this.editor.export().drawflow.Home.data['1'].data;
  
   
    mic['targetCloud'] = this.getSelectedValue(mic['targetCloud']);
    mic['ciCdPlatformType'] = this.getSelectedValue(mic['ciCdPlatformType']);    
    mic['targetLanguage'] = this.getSelectedValue(mic['targetLanguage']);
    mic['architecturalStyle'] = this.getSelectedValue(mic['architecturalStyle']);
    mic['infrastructure'] = this.getSelectedValue(mic['infrastructure']);
    mic['javaVersion'] = this.getSelectedValue(mic['javaVersion']) ;
    mic['security'] = this.getSelectedValue(mic['security']) ;

 
    
    mic['security'] = mic['security'] ?? 'NONE';
   
       
    var front = this.editor.getNodesFromName(ModelConstants.front);
    if (front.length > 0) {
      mic.front = this.editor.getNodeFromId(front[0]).data;
      mic.front['type'] = this.getSelectedValue(mic.front['type']) ;
      mic.front['security'] = this.getSelectedValue(mic.front['security']) ;
    }


    var database = this.editor.getNodesFromName(ModelConstants.database);


    if (database.length > 0) {
      mic.database = this.editor.getNodeFromId(database[0]).data;
      mic.database['sqlType'] = this.getSelectedValue(mic.database['sqlType'])  ;
    }

    var apiService = this.editor.getNodesFromName(ModelConstants.apiservice);

    
    if (apiService.length > 0) {
      mic.services = [];

      var index = 0;
      apiService.forEach(element => {
        

        mic.services[index]= this.editor.getNodeFromId(element).data;
        var apiServicePath = this.editor.getNodesFromName(ModelConstants.apiServicePath+element);
       
        
        mic.services[index]['paths'] = [];
        mic.services[index]['authType'] = this.getSelectedValue(mic.services[index]['authType']) ;
        for (var value of apiServicePath) {
          var data = this.editor.getNodeFromId(value).data;
          data['method'] = this.getSelectedValue(data['method'])  ;
          mic.services[index].paths.push(data);
        }

        index++;

      });

     

    }


    var azureresources = this.editor.getNodesFromName(ModelConstants.azureresources);
    if (azureresources.length > 0) {
      mic[ModelConstants.azureresources] = this.editor.getNodeFromId(azureresources[0]).data;
      var servicebus = this.editor.getNodesFromName(ModelConstants.servicebus);
      if (servicebus.length > 0) {
        mic[ModelConstants.azureresources][ModelConstants.servicebus] = this.editor.getNodeFromId(servicebus[0]).data;
        mic[ModelConstants.azureresources][ModelConstants.servicebus]['messageType'] = this.getSelectedValue(mic[ModelConstants.azureresources][ModelConstants.servicebus]['messageType']) ;
      }
      var insight = this.editor.getNodesFromName(ModelConstants.insight);
      if (insight.length > 0) {
        mic[ModelConstants.azureresources][ModelConstants.insight] = this.editor.getNodeFromId(insight[0]).data;
      }

      var azuredatabase = this.editor.getNodesFromName(ModelConstants.azuredatabase);
      if (azuredatabase.length > 0) {
        mic[ModelConstants.azureresources][ModelConstants.azuredatabase] = this.editor.getNodeFromId(azuredatabase[0]).data;
        mic[ModelConstants.azureresources][ModelConstants.azuredatabase]['sqlType'] = this.getSelectedValue(mic[ModelConstants.azureresources][ModelConstants.azuredatabase]['sqlType']);
      }

      var storage = this.editor.getNodesFromName(ModelConstants.storage);
      if (storage.length > 0) {
        mic[ModelConstants.azureresources][ModelConstants.storage] = this.editor.getNodeFromId(storage[0]).data;

        var storageTable = this.editor.getNodesFromName(ModelConstants.storageTable);
        if (storageTable.length > 0) {
          mic[ModelConstants.azureresources][ModelConstants.storage][ModelConstants.storageTable] = [];
          mic[ModelConstants.azureresources][ModelConstants.storage][ModelConstants.storageTable].push(this.editor.getNodeFromId(storageTable[0]).data);
        }
      }

  
      
    }

    var plugins = this.editor.getNodesFromName(ModelConstants.plugin);
    mic.plugins= [];
    if (plugins.length > 0) {
      for (var value of plugins) {       
        mic.plugins.push(this.editor.getNodeFromId(value).data);   
      }
    }
    

    var entities = this.editor.getNodesFromName(ModelConstants.entity);
    mic.entities = [];

    for (var e of entities) {

      if (entities.length > 0) {
        var entityName = this.editor.getNodeFromId(e).data['name'];
        var entity = {
          "attributes": [
          ],
          "name": entityName,
          "targetEntity": "ALL"
        };


        var i = 0;
        //get nodes
        var obj = this.editor.getNodeFromId(e).data;
        //get keys of json
        var keys = Object.keys(obj);
        //calc length
        var count = keys.length;

        //discount first element and the other ones are pairs (name, type) of entity attributes
        count = (count - 1) / 2;

        var index = 1;
        
        //this algorithm depends of order of the list
               
        for (var i = 1, length = keys.length-1; i < length; i++) {
          entity.attributes.push({
            "name": obj[keys[i]],
            "type": this.getSelectedValue(obj[keys[i+1]])  
          });
          //add twice per iteration
          i++
          index++;

        }        
        mic.entities.push(entity);


      }


    }
    
    return mic;


  }


  /**
   * Clear all model
   */
  clearModel() {
    this.editor.import(this.initData);
    this.entities = 0;
    this.entityAttributes = 0;
    this.nodes = 1;
  }

  /**
   * 
   * @param index Update data and html for Entity model
   * @param key 
   * @param value 
   * @param html 
   */
  entityChanged(index:number, key:number, value:string, html:string){
      this.editor.drawflow.drawflow.Home.data[index].data[key] = value;  
      this.editor.drawflow.drawflow.Home.data[index].html = html;    
  }


}

 
