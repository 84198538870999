import { Component, OnInit } from '@angular/core';
import { EventMessage } from '../core/messages/event.message';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  constructor(private eventMessage : EventMessage) { }

  public groups : any =  [
    {
      "groupName": "IA"
    },
    {
      "feature": "Model Creator (Experimental)",
      "java":true,
      "python":false,
      "javascript":false,
      "free":false,
      "personal":true,
      "enterprise":true
    },
    {
      "groupName": "Microservice"
    },
    {
      "feature": "DTO",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Entity",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "groupName": "Devops"
    },
    {
      "feature": "Azure Pipeline",
      "java":true,
      "python":true,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    }, 
    {
      "feature": "Dockerfile",
      "java":true,
      "python":true,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Terraform",
      "java":true,
      "python":true,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "groupName": "Azure"
    },
    {
      "feature": "Service Bus",
      "java":true,
      "python":false,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Cosmos DB",
      "java":true,
      "python":false,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Application Insight",
      "java":true,
      "python":false,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Storage Table",
      "java":true,
      "python":false,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "groupName": "Api Integration"
    },
    {
      "feature": "Auth2.0",
      "java":true,
      "python":false,
      "javascript":false,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "GET",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "POST",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "PUT",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "DELETE",
      "java":true,
      "python":true,
      "javascript":true,
      "free":true,
      "personal":true,
      "enterprise":true
    },
    {
      "feature": "Database",
      "java":true,
      "python":false,
      "javascript":false,
      "free":false,
      "personal":true,
      "enterprise":true
    },
    {
      "groupName": "Components"
    },
    {
      "feature": "Front",
      "java":true,
      "python":true,
      "javascript":true,
      "free":false,
      "personal":true,
      "enterprise":true
    },
  ];




  ngOnInit(): void {
    console.log(this.groups);
    
  }


}
