import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { EventMessage } from 'src/app/core/messages/event.message';
import { GeneratorService } from 'src/app/core/services/generator.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  success : boolean;
  model: {};
  timestamp: Date;
}

@Component({
  selector: 'app-chat',
  animations: [
    trigger('messageAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterViewInit {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  
  messages: ChatMessage[] = [];
  userInput: string = '';
  isTyping: boolean = false;
  renderedHtml: SafeHtml = '';

  samplePrompts = [
    { text: 'Generate app for inventory management' },
    { text: 'Generate app for a social network for pets' },
    { text: 'Generate app for receipts management' }
  ];
  

  constructor(private sanitizer: DomSanitizer
      ,  private eventMessage: EventMessage
      , private localStorageService: LocalStorageService
    , private generatorService: GeneratorService) {}

  ngAfterViewInit() {
    // Initial message
    
  }

  public isDark: boolean = false;
  toggleTheme() {
    this.isDark = !this.isDark;
  }



  async sendSample(sample : string) {
    this.userInput = sample;
    this.sendMessage();
  }

  async sendMessage() {

    
    
    
    if (!this.userInput.trim()) return;

    var userIn =  this.userInput.replace(/[\n\r]/g, '').trim();

          // Add user message
          this.addMessage({
            role: 'user',
            model: {},
            success: true,
            content: userIn,
            timestamp: new Date()
          });
      
      
          
          const userMessage = userIn;
          this.userInput = '';
          this.scrollToBottom();
      
          // Show typing indicator
          this.isTyping = true;
      

    this.generatorService.agentChatGenerate({
      "query": userMessage
    }).subscribe({
      next: (result) => {
        this.renderedHtml = this.sanitizer.sanitize(SecurityContext.HTML, result.data) || '';
        this.isTyping = false;
        this.addMessage({
          role: 'assistant',
          model: result.model,
          success: true,
          content: `${this.renderedHtml}`,
          timestamp: new Date()
        });
      },
      error: (error) => {
        this.isTyping = false;
        console.error("Error during agent chat generation:", error);
        
      
       if (error.status >= 400 && error.status < 500){
        this.addMessage({
          role: 'assistant',
          success: false,
          model: {}, // or some error representation
          content: "I'm sorry, I encountered an error processing your request.",
          timestamp: new Date()
        });
        }else{
          this.addMessage({
            role: 'assistant',
            success: false,
            model: {}, // or some error representation
            content: "I'm sorry, I encountered an error processing your request.",
            timestamp: new Date()
          });
        }
        // Optionally, you can display a more user-friendly error message
        //  alert("An error occurred. Please try again later."); or similar.
      }
    });

   
  }




  onEnterPress(event: KeyboardEvent) {
  
    
    if (event.key === 'Enter' && event.ctrlKey) {

      event.preventDefault();
      this.sendMessage();
    }
  }

  private addMessage(message: ChatMessage) {
    this.messages.push(message);
    this.scrollToBottom();
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.scrollContainer) {
        const element = this.scrollContainer.nativeElement;
        element.scrollTop = element.scrollHeight;
      }
    });
  }



 

  view(mic:any) {
    
    this.addMessage({
      role: 'assistant',
      model: null,
      success: true,
      content: "I'm on it",
      timestamp: new Date()
    });

    this.localStorageService.setItem('model',JSON.stringify(mic));
    window.location.href = '/generator';
  }

  
  generate(mic:any) {

    this.isTyping = true;

    this.addMessage({
      role: 'assistant',
      model: null,
      success: true,
      content: "I'm on it",
      timestamp: new Date()
    });
    this.generatorService.generate(mic).subscribe(result => {
      this.downloadByUUID(result.uuid);
      this.isTyping = false;
      this.addMessage({
        role: 'assistant',
        model: null,
        success: true,
        content: "Ready!",
        timestamp: new Date()
      });
    });
  }


  downloadByUUID(uuid: string){
    this.generatorService.downloadFile(uuid).subscribe(r => {
      const blob = new Blob([r], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  

}
