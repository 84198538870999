<header>
    <nav class="flex items-center justify-between p-6 container mx-auto">
        <!-- Logo -->
        <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-indigo-700" fill="none"  stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
              </svg>
            <span class="no-underline hover:no-underline font-bold text-2xl lg:text-4xl">Microservices</span>
        </div>
        <!-- Menu items -->
        <div class="text-lg text-gray-600 hidden lg:flex">
            <a href="#" class="block mt-4 lg:inline-block text-indigo-600 lg:mt-0 mr-10">
                Home
            </a>
            <!--
            <a href="https://dooitconsulting.com" target="blank" class="block mt-4 lg:inline-block hover:text-gray-700 lg:mt-0 mr-10">
                Consultoría
            </a>
            -->
            <a href="#/story/stacks-microservicios" class="block mt-4 lg:inline-block hover:text-gray-700 lg:mt-0 mr-10">
                Stack
            </a>

            <a href="#/features" class="block mt-4 lg:inline-block hover:text-gray-700 lg:mt-0 mr-10">
                Features
            </a>
              <!--
            <a href="#" class="block hover:text-gray-700 mt-4 lg:inline-block lg:mt-0 mr-10">
                Nosotros
            </a>
            -->
            <a href="#/contact" class="block hover:text-gray-700 mt-4 lg:inline-block lg:mt-0">
                Conctact
            </a>
        </div>

         <!-- CTA and Hamburger icon -->
         <div class="flex items-center">
          <div *ngIf="!name"  class="mr-5 lg:mr-0">
              <a href="#/login" class="font-medium text-indigo-600 hover:text-indigo-500">Log in</a>
          </div>
    
          <div *ngIf="name">
            <button type="button" class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Perfil</span>
              
              <img *ngIf="email!='oiestradag@gmail.com'" class="h-8 w-8 rounded-full" referrerpolicy="no-referrer" src="{{photoUrl}}" alt="">
              <a *ngIf="email=='oiestradag@gmail.com'" href="#/directory" class="font-medium text-indigo-600 hover:text-indigo-500"><img class="h-8 w-8 rounded-full" referrerpolicy="no-referrer" src="{{photoUrl}}" alt=""></a>
            </button>
          </div>

          <div *ngIf="name"  class="mr-5 lg:mr-0 font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer hidden lg:flex">
              <a (click)="logOut()" class="font-medium text-indigo-600 hover:text-indigo-500">Salir</a>
          </div>
 
          <div class="block lg:hidden">
              <button (click)="toggleMenu()"
                  class="flex items-center px-4 py-3 border rounded text-indigo-700 border-indigo-900 focus:outline-none">
                  <svg class="fill-current h-3 w-3 " viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <title>Menu</title>
                      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
              </button>

         

          </div>
      </div>
        
    </nav>
    <div #mobMenu class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                Home
            </a>
        
            <a href="#/story/stacks-microservicios" class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium">
                Stack
            </a>
            <a href="#/contact" class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium">
                Contáctanos
            </a>
            
              <a *ngIf="name" (click)="logOut()" class=" text-indigo-600 hover:text-indigo-500 block px-3 py-2 rounded-md text-base font-medium cursor-pointer">
                Salir
              </a>
         
        </div>
    </div>

   </header>
