<div class="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
  <h1 class="text-3xl font-bold text-gray-800 mb-4">Terms and Conditions</h1>
  <p class="text-gray-600 mb-4">
    Welcome to our AI-powered chatbot service. By accessing or using our service, you agree to the following terms:
  </p>

  <h2 class="text-xl font-semibold text-gray-700 mt-4">1. Use of Service</h2>
  <p class="text-gray-600">You must be at least 13 years old to use this service. Do not use this AI for illegal or unethical purposes.</p>

  <h2 class="text-xl font-semibold text-gray-700 mt-4">2. Data and Privacy</h2>
  <p class="text-gray-600">We do not store or share your conversations, but we may analyze usage trends to improve the service.</p>

  <h2 class="text-xl font-semibold text-gray-700 mt-4">3. Limitation of Liability</h2>
  <p class="text-gray-600">Our AI may generate incorrect or biased responses. Use the information at your own discretion.</p>

  <h2 class="text-xl font-semibold text-gray-700 mt-4">4. Changes to Terms</h2>
  <p class="text-gray-600">We may update these terms at any time. Continued use of the service means you accept the new terms.</p>

  <p class="mt-6 text-gray-600">If you have questions, contact us at <a href="mailto:soporte@microservicios.co" class="text-blue-500 hover:underline">soporte@microservicios.co</a>.</p>
</div>
