<div class="h-screen flex flex-col overflow-hidden"
[ngClass]="isDark ? 'bg-gradient-to-b from-white via-gray-100 to-gray-200' : 
                   'bg-gradient-to-b from-gray-900 via-gray-800 to-gray-600'">


                   
  <!-- Header (Fixed Height) -->
  <app-header></app-header>

  <!-- Main Content (Fills Remaining Height) -->
    <router-outlet></router-outlet>
  

<app-event-message></app-event-message>
</div>

