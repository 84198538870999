<div class="flex flex-col h-[200px] bg-gray-50 rounded-lg p-2 shadow-lg">
    

    <!-- Chat Messages -->
    <div 
      #scrollContainer
      class="flex-1 overflow-y-auto p-4 space-y-4"
      [class.scrollbar-custom]="true"
    >
    <ng-container *ngFor="let message of messages">
      <div 
        class="flex"
        [class.justify-end]="message.role === 'user'"
        [@messageAnimation]
      >
        <div 
          class="max-w-[80%] p-4 rounded-2xl shadow-lg"
          [ngClass]="{
            'bg-blue-500 text-white': message.role === 'user',
            'bg-red-500 text-white': (message.role === 'assistant'  && message.success === false),
            'bg-gray-200 text-gray-800': (message.role === 'assistant'  && message.success)
          }"
        >
          <div class="text-left" [innerHTML]="message.content"></div>
    
          <div *ngIf="message.role === 'assistant' && message.success  && message.model" class="flex items-center space-x-2 mt-2">
            <button (click)="view(message.model)" class="rounded-full p-2 bg-white hover:bg-gray-300 transition">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
              </svg>
            </button>
            
            <button (click)="generate(message.model)" class="rounded-full p-2 bg-white hover:bg-gray-300 transition">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
         </svg>
            </button>
          </div>
    
          <div [ngClass]="{
            'text-white': message.role === 'user',            
              'text-white': (message.role === 'assistant'  && message.success === false),
            'text-gray-600': (message.role === 'assistant'  && message.success)
          }" class="text-xs mt-2 opacity-70 text-right ">
            {{ message.timestamp | date:'shortTime' }}
          </div>
        </div>
      </div>
    </ng-container>
    

      <!-- Typing Indicator -->
      <div *ngIf="isTyping" class="flex items-center space-x-2">
        <div class="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
        <div class="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
        <div class="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style="animation-delay: 0.4s"></div>
      </div>
    </div>

  
  </div>

    <!-- Input Area -->
    <div [ngClass]="isDark ? 'bg-gray-800/50' : 'bg-white/80 shadow-lg'"
    class="backdrop-blur-sm rounded-xl p-6 m-1 mx-auto">
    <div class="relative min-h-[20px] flex">
      <textarea [(ngModel)]="userInput" (keydown)="onEnterPress($event)" [ngClass]="isDark ? 'text-gray-300' : 'text-gray-900'"
        class="w-full bg-transparent resize-none focus:outline-none pr-16 placeholder:text-gray-500"
        placeholder="Type your message...">
</textarea>

      <button  (click)=" sendMessage(); " [ngClass]="isDark ? 'text-gray-400 hover:text-white' : 
   'text-gray-600 hover:text-gray-900'" class="absolute focus:outline-none right-0 top-0 p-2 transition-colors">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round">
          <path d="M22 2 11 13" />
          <path d="M22 2 15 22 11 13 2 9z" />
        </svg>
      </button>
    </div>
  </div>



  
  <section class="flex max-w">
    <ng-container *ngFor="let sample of samplePrompts">
      <div [@messageAnimation]>
      </div>
    

      <article class="m-2">
        <button 
          (click)="sendSample(sample.text)" 
          class="max-w cursor-pointer m-2 p-3 rounded-2xl shadow-lg bg-white text-gray-800"
          aria-label="Generate {{sample.text}}"
        >
          <h3 class="text-lg">{{ sample.text }}</h3>
   
        </button>
      </article>
    </ng-container>
  </section>
  
  
  


  
  <div class="text-center text-sm text-gray-500 mt-4">
    By sending a message to Microservicios.co, you agree to our 
    <a href="/terms" class="text-blue-500 hover:underline"> Terms</a> and acknowledge that you have read our 
    <a href="/privacy" class="text-blue-500 hover:underline">Privacy Policy</a>.
  </div>