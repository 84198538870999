
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const helper = new JwtHelperService();
const TOKEN_KEY : string = 'accessToken';
const DATA_KEY = 'authData';

@Injectable({
  providedIn: 'root'
})
export class GeneratorService {
  private userData = new BehaviorSubject(null);
   constructor( private http: HttpClient, private router: Router) {

  }

  version() {
    return this.http.get<any>(environment.URL_SOLICITUDES+'/generator/version');
  }


  generate(request: any) {
    return this.http.post<any>(environment.URL_SOLICITUDES+'/generator/generate', request);
  }

  getUser() {
    return this.http.get<any>(environment.URL_SOLICITUDES+'/user');
  }

  saveUser(user:any) {   
    return this.http.put<any>(environment.URL_SOLICITUDES+'/user',user);
  }

  aIGenerate(request: any) {
    return this.http.post<any>(environment.URL_SOLICITUDES+'/gemini-service/microservice', request);
  }


  agentChatGenerate(request: any) {
    return this.http.post<any>(environment.URL_SOLICITUDES+'/agent/mic/generator', request);
  }

  


  downloadFile(uuid: string): Observable<any> {
    return this.http.get(environment.URL_SOLICITUDES+'/generator/download?uid='+uuid, {responseType: 'arraybuffer'});
  }

  

}

