export class Property {
  key: string;
  value: string;
  values: Item[];

  constructor(key, value) {
    this.key = key;
    this.value = value;
    this.values = [];
  }

  setValues(values: string[], value:string){
    if(values.length > 0){
      for (let i = 0; i < values.length; i++) {
        this.values.push(new Item(values[i], values[i]));
      }
      this.value = value;
    }
  }

}

export class Item {
  value: string;
  name: string;

  constructor(value: string, name: string) {
    this.value = value;
    this.name = name;
  }
}